<template>
  <v-row>
    <v-col cols="12">
      <div v-if="filters.order_ids && filters.order_ids.length > 0">
        <v-chip class="mr-1 mb-1" v-for="od in filters.scannedOrders" :key="`sc_${od.tracking_id}`">
          {{ od.tracking_id }}
        </v-chip>
      </div>
      <v-simple-table class="pickup-table" v-else>
        <template v-slot:default>
          <tbody>
            <tr class="info white--text font-weight-bold">
              <td>{{ filters.config.display }}</td>
              <td>{{ filters.config.count }}</td>
              <td><v-icon class="white--text">mdi-checkbox-marked</v-icon></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12">
      <input-qr-scan-model v-model.number="basket_code" :label="$t('labels.basket_code')"
        @keyupEnter="scanBasket" :disabled="disabledInputBasket"></input-qr-scan-model>
    </v-col>
    <v-col cols="12">
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div>
              <v-chip v-for="b in basketReverse" :key="`basket_${b.id}`" class="mr-1 mb-1" color="primary">
                {{ b.code }} ({{ b.index }})
              </v-chip>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-btn color="warning" block @click="backStep" :disabled="isWaitingCommand">Quay lại</v-btn>
    </v-col>
    <v-col cols="6">
      <v-btn color="success" block @click="createCommand" :disabled="scanned_baskets.length === 0 || isWaitingCommand">
        Bắt đầu
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "PickupStep3",
  components: {

  },
  props: {
    selected: {
      type: Object,
      default: () => { }
    }
  },
  data: () => ({
    filters: {},
    basket_code: null,
    scanned_baskets: [],
    isWaitingCommand: false,
  }),
  created() {
    this.filters = { ...this.selected }
  },
  computed: {
    basketReverse() {
      return [...this.scanned_baskets].reverse()
    },
    disabledInputBasket() {
      return (this.filters.config && this.filters.config.basket && this.scanned_baskets.length >= this.filters.config.basket) && (this.filters.order_ids && this.scanned_baskets.length >= this.filters.order_ids.length)
    }
  },
  methods: {
    async scanBasket() {
      if (!this.basket_code) {
        return false
      }

      if (this.disabledInputBasket) {
        return false
      }

      const checkBasket = this.scanned_baskets.find(c => c.code == this.basket_code)
      if (checkBasket) {
        this.$root.$emit('playErrorAudio')
        this.$vToastify.error('Sọt đã quét')
        this.basket_code = null
        return false
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/order-online-pickup-get-basket', {
          basket_code: this.basket_code,
          id_pos: this.filters.id_pos
        })
        this.scanned_baskets.push({
          code: data.code,
          id: data.id,
          index: this.scanned_baskets.length + 1
        })
        this.isLoading = false
        this.basket_code = null
        this.$root.$emit('playSuccessAudio')
        this.$vToastify.success(`Đã nhận sọt ${data.code}`)
      } catch (e) {
        this.basket_code = null
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.$root.$emit('playErrorAudio')
      }
    },
    backStep() {
      this.$emit('onUpdate', { ...this.filters, process_type: null, pickup_type: null, order_ids: [], scannedOrders: [], id_command: null })
    },
    async createCommand() {
      if (this.isLoading || this.isWaitingCommand) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true
      try {
        await httpClient.post('/order-online-pickup-create-command', {
          scanned_baskets: this.scanned_baskets,
          filters: this.filters,
        })
        this.isLoading = false
        this.$root.$emit('playSuccessAudio')
        this.isWaitingCommand = true
      } catch (e) {
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.$root.$emit('playErrorAudio')
      }
    }
  }
}
</script>

<style scoped></style>
